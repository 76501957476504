// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "./node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "./node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rstcustom__node{min-width:100%;position:relative}\n", "",{"version":3,"sources":["webpack://./tree-node-renderer.scss"],"names":[],"mappings":"AAAA,iBACE,cAAe,CACf,iBAAkB","sourcesContent":[".node {\n  min-width: 100%;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"node": "rstcustom__node"
};
export default ___CSS_LOADER_EXPORT___;
